import {request} from "@/network/request";

//index
export function getIndexInfo() {
    return request({
        url: 'web/home',
    })
}

export function getAbout() {
    return request({
        url: 'web/aboutUs',
    })
}

export function getNews(page) {
    return request({
        url: 'web/newMessage',
        params: {
            page: page
        },
    })
}

export function getNewsMore(id) {
    return request({
        url: 'web/newMessageDetails',
        params: {
            id: id
        },
    })
}

export function getContactInfo() {
    return request({
        url: 'web/contactUs',
    })
}

//提交表格
export function  contactForm(params) {
    return request({
        url: 'web/visitor',
        method: 'post',
        data: params,
    })
}




//-------------------------------会员相关--------------------------


//区号
export function getAreaCode() {
    return request({
        url: 'user/areaCode',
    })
}

//发送验证码
export function  sendCode(params) {
    return request({
        url: 'user/sendCodeToEmail',
        method: 'post',
        data: params,
    })
}

//會員種類
export function  getMemberLevel() {
    return request({
        url: 'user/memberList',
    })
}

//註冊
export function register(params) {
    return request({
        url: 'user/register',
        method: 'post',
        data: params,
    })
}

//登錄
export function loginPost(params) {
    return request({
        url: 'user/login',
        method: 'post',
        data: params,
    })
}
//会员资料
export function getMemberInfo() {
    return request({
        url: 'user/memberCenter',
    })
}
//银行资料
export function getBankInfo() {
    return request({
        url: 'user/bank',
    })
}
//提交审批
export function approval(params) {
    return request({
        url: 'user/memberSubmitCheck',
        method: 'post',
        data: params,
    })
}
//logout
export function logout() {
    return request({
        url: 'user/logOut',
        method: 'post',
    })
}
//修改邮箱
export function changeEmail(params) {
    return request({
        url: 'user/editEmail',
        method: 'post',
        data: params,
    })
}

//修改邮箱
export function changePwd(params) {
    return request({
        url: 'user/resetPassword',
        method: 'post',
        data: params,
    })
}

//修改邮箱
export function changePhone(params) {
    return request({
        url: 'user/editPhone',
        method: 'post',
        data: params,
    })
}
//
export function UploadAvatar(params) {
    return request({
        url: 'user/uploadPhoto',
        method: 'post',
        data: params,
    })
}


//積分明細
export function getPoint(type,page) {
    return request({
        url: 'user/calculusRecords',
        params: {
            type: type,
            page: page
        },
    })
}









