import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')
   },
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/News.vue'),
  },
  {
    path: '/newsDetails/:id',
    name: 'NewsDetails',
    component: () => import('@/views/NewsDetails.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/member/Login.vue')
  },
  {
    path: '/register/:invite_code?',
    name: 'register',
    component: () => import('@/views/member/reg.vue')
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('@/views/member/Forget.vue')
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import('@/views/member/Success.vue')
  },
  {
    path: '/fail',
    name: 'Fail',
    component: () => import('@/views/member/Fail.vue')
  },
  {
    path: '/member',
    name: 'MemberCentre',
    component: () => import('@/views/member/MemberCentre.vue')
  },
  {
    path: '/points',
    name: 'Points',
    component: () => import('@/views/member/Points.vue')
  },
  {
    path: '/changePassword',
    name: 'ChangePwd',
    component: () => import('@/views/member/ChangePwd.vue')
  },
  {
    path: '/changeEmail',
    name: 'ChangeEmail',
    component: () => import('@/views/member/ChangeEmail.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  //scrollBehavior滚动行为
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 } //期望滚动到哪个的位置
    }
  },
})


//路由点击跳转后页面回到顶部
router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router
