import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import i18n from './lang'
import mitt from 'mitt'

const app = createApp(App)

// 创建事件总线实例
const emitter = mitt()
app.config.globalProperties.emitter = emitter

app.use(router).use(VueViewer).use(i18n).use(ElementPlus).mount('#app')