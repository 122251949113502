
<template>
  <div class="dialog-wrapper" v-if="visible">
    <div class="dialog-container">
      <div class="dialog-header">
        <div class="dialog-title">{{ title }}</div>
        <div class="dialog-close" @click="closeDialog">
          <i class="iconfont icon-guanbi1"></i>
        </div>
      </div>
      <div class="dialog-content">
        {{ description }}
      </div>
      <div class="dialog-footer">
          <el-button type="primary" @click="confirmDialog">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Dialog',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:visible', false)
    },
    confirmDialog() {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped>
.dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog-container {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 500px;

}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dialog-title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.dialog-close {
  cursor: pointer;
}

.dialog-content {
  margin-bottom: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>