<template>

  <div v-if="isShow">
    <loading/>
  </div>
    <div class="banner">
      <div class="banner-txt">
        <p>The Association Of HongKong Insurance FAM</p>
        <h1>香港保險互助聯盟</h1>
        <h3>歡迎有意建立內地人脈、拓展內地市場及客源的仁人志士踴躍加入！</h3>
        <h3>希望您微薄的投入能為您打開一條屬於您自己的成功和財富之門！</h3>
      </div>

      <img :src="banner">
    </div>
    <div class="info"  v-if="infoShow">
     <div class="middle">
       <div class="info-c">
         <div class="info-1">
           <img :src="user_info.photo  || defaultAvatarUrl">
           <h4>{{ user_info.name }}</h4>
         </div>
         <div class="info-right-c">
           <Info
               :memberName="user_info.member_name"
               :expirationDate="user_info.vip_expire_date"
               :membershipNumber="user_info.invitation_code"
               :points="user_info.calculus"
               :id="user_info.member_id"
               :memberData="memberLevels"
           />
         </div>

       </div>
     </div>
    </div>

  <div class="index-about">
    <div class="middle common-padding-120">
      <div class="in-about">
        <div class="in-about-left">
           <div class="in-title">
             <span>關於我們</span>
             <div class="line"></div>
           </div>
          <div class="in-editor">
            <Editor :content="this.content"></Editor>
          </div>
        <div class="index-about-b">
           <div class="common-btn">
             <router-link to="/about">
               查看详情 <i class="iconfont icon-jiantou"></i>
             </router-link>
           </div>
         </div>
        </div>
        <div class="in-about-right">
          <img :src="img">
        </div>
       </div>
    </div>
  </div>


  <div class="middle common-padding-120">
      <div class="common-title">
        <div class="common-line"></div>
        <span>
          最新消息
        </span>
        <div class="common-line"></div>
      </div>
      <div class="in-news common-padding-half">
          <ul>
            <li v-for="item in news" :key="item.id" @click="details(item.id)" >
              <img :src="item.cover">
              <div class="in-news-txt">
                 <h4>{{item.title}}</h4>
                 <h5>{{ item.date }}</h5>
                  <div class="in-news-btn">
                    查看详情 <i class="iconfont icon-jiantou"></i>
                  </div>
              </div>
            </li>
            <li>
            </li>
          </ul>
      </div>
     <div class="in-news-center">
       <div class="common-btn">
         <router-link to="/news">
           查看详情 <i class="iconfont icon-jiantou"></i>
         </router-link>
       </div>
     </div>
  </div>



</template>

<script>
  import Info from "@/components/Info";
  import Editor from "@/components/Editor";
  import Loading from "@/components/loading";
  import defaultAvatarUrl from '@/assets/img/avator.png'
  import {getIndexInfo, getMemberInfo} from "@/network/main";

  export default {
  name: "Home",
  components:{
    Info,
    Editor,
    Loading
  },
  data() {
    return {
      banner:'',
      content:'',
      img:'',
      news:[],
      user_info:'',
      memberLevels:[],
      isShow:true,
      infoShow:false,
      defaultAvatarUrl: defaultAvatarUrl,
     }
  },
  mounted() {
    this.token = localStorage.getItem('token');
    if (this.token !== null){
        this.infoShow = true;
        this.getMemberInfo()
    }
    // if(this.user_info === null){
    //   console.log("11111",2222)
    //   localStorage.clear();
    // }
  },
  created() {
     this.getIndexInfo();
  },
  methods: {
    //
    getIndexInfo() {
      getIndexInfo().then(res =>{
        this.isShow = false;
        this.banner = res.data.data.banner;
        this.content = res.data.data.about_us.home_intro;
        this.img = res.data.data.about_us.home_images;
        this.news = res.data.data.new_message;
      })
    },

    //会员资料
    getMemberInfo(){
      getMemberInfo().then(res =>{
        this.user_info = res.data.data.user_info;
        this.memberLevels =res.data.data.member;
      })
    },

    //跳转
    details(id){
      this.$router.push('/NewsDetails/' + id)
    },
  }
}
</script>

<style scoped>

</style>