
import axios from 'axios'
import router from '../router'

export function request(config) {
    //1.创建axios的实例
    const instance = axios.create({
        baseURL: 'https://api.charmingspring.com/api/',
    })

    //2.1请求拦截
    instance.interceptors.request.use(config => {
        config.headers = {'X-Requested-With':'XMLHttpRequest'}
        const token = localStorage.getItem('token');
        if (token !== null) { // 判断是否需要添加token
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config
    }, err => {
        console.log(err)
    });
    //2.2  响应拦截
    instance.interceptors.response.use(res => {
        //301
        if (res.data.code === 301) {
            // 否则跳转到登录页面
            router.push({
                name: 'Login',
            })
        }
        if (res.data.code === 401) {
            // 否则跳转到登录页面
            router.push({
                name: 'Login',
            })
        }
        return res
    }, err => {
        console.log(err)
    });

    //3.发送网络请求
    return instance(config)
}



