<template>
  <div v-if="show" class="modal-wrapper">
    <div class="modal">
      <div class="modal-header">
        <h3>{{ title }}</h3>
        <div @click="closeModal" class="modal-close">
          <i class="iconfont icon-guanbi1"></i>
        </div>
      </div>
      <div class="modal-body">
        <p>{{ description }}</p>
        <div v-if="showInput" class="input-wrapper">
          <input v-model="inputValue"  @input="handleInputValueChange" />
        </div>
      </div>
      <div class="modal-footer">
        <p v-if="price !== null" class="price">${{ price.toFixed(2) }}</p>
        <button @click="handleModalConfirm">{{ actionText }}</button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    actionText: {
      type: String,
      required: true
    },
    onConfirm: {
      type: Function,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    },
    price: {
      type: Number,
      required: false,
      default: null
    },
    showInput: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data(){
    return{
      inputValue:'',
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    handleModalConfirm() {
      this.$emit('confirm');
      // this.closeModal();
    },
    handleInputValueChange() {
      this.$emit('input-value-change', this.inputValue)
      this.$root.emitter.emit('input-value-change', this.inputValue)
    }
  }
}
</script>
<style scoped>

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 440px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px;
  background: url("~@/assets/img/pic23.png") no-repeat;
}

.modal-header h3 {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.modal-close {
  cursor: pointer;
}
.modal-close i{
  color: #fff;
  font-size: 20px;
}

.modal-body {
  margin-bottom: 20px;
  padding: 20px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
}
.modal-footer button{
  color: #000;
  background: linear-gradient(5.58deg, #FFECD6 3.97%, #F1CFA6 95.14%);
  cursor: pointer;
  font-weight: bold;
}
.modal-footer p{
   font-size: 24px;
  font-weight: bold;
  color: var(--background);
}

.input-wrapper input{
  width: 100%;
  margin-top: 20px;
}
</style>