<template>
  <div class="info-2">
    <div class="info-2-1">
      <div class="info21-1">
        <div>
          <h3>{{memberName}} </h3>
          <p>會員到期日期：{{ expirationDate }}</p>
        </div>
        <p class="hui"><b>{{ membershipNumber }}</b></p>
      </div>
      <div class="min-block"></div>

      <div class="info21-2">
        <div @click="upgradeVIP">
          升級至VIP
        </div>
        <div @click="upgradeDiamond">
          升級至鉆石VIP
        </div>
        <div @click="renewal">
          立即續費
        </div>
      </div>

    </div>
    <div class="info-2-1">
      <div class="info21-1">
        <div>
          <h3>積分 <router-link to="/points">查看積分明細 ></router-link></h3>
          <h1 class="hui">{{ points }}<span>點</span></h1>
          <p class="hui">積分規則：1個積分可抵扣$1.00，積分可用來兌換下一年會員費用，抵扣金額不夠的部分需補齊</p>
        </div>
      </div>
      <div class="min-block-2"></div>
      <div class="info21-2">
        <div @click="withdraw">
          提現
        </div>
        <div @click="exchange">
          兌換次年會費
        </div>
      </div>
    </div>
  </div>

  <!--提示窗-->
  <div v-if="isPopupVisible" class="popup">
    {{ popupText }}
  </div>


  <!--弹窗 -->
  <Modal
      v-if="showModal"
      :title="modalTitle"
      :description="modalDescription"
      :action-text="modalActionText"
      :show="showModal"
      :price="priceDiff"
      :showInput="showInput"
      @close="showModal = false"
      @confirm="handleModalConfirm"
  />

  <!-- 审核的弹窗 -->
  <div>
    <Dialog
        :title="dialogTitle"
        :description="dialogDescription"
        v-model:visible="dialogVisible"
        @update:visible="handleDialogVisibleChange"
    />
  </div>

</template>

<script>
import Modal from "@/components/Modal";
import Dialog from "@/components/Dialog";
import {approval} from "@/network/main";
export default {
  name: "Info",
  components:{
    Modal,
    Dialog
  },
  props: {
    expirationDate: {
      type: String,
      required: true
    },
    membershipNumber: {
      type: String,
      required: true
    },
    memberName: {
      type: String,
      required: true
    },
    points: {
      type: Number,
      required: true
    },
    id: { //当前会员等级id
      type: [String, Number],
      required: true
    },
    memberData: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      showModal: false,
      modalTitle: '',
      modalDescription: '',
      modalActionText: '',
      priceDiffs: [],
      showUpgradeToVIP: true,
      showUpgradeToDiamondVIP: true,
      dialogTitle: '申請已提交',
      dialogDescription: '您的申請已提交，等待管理員審核中',
      dialogVisible: false,
      priceDiff: 0,
      isPopupVisible:false,
      popupText:'',
    }
  },
  created() {
    this.calculatePriceDiffs();
  },
  methods: {
    //计算差价
    calculatePriceDiffs() {
      const memberData = this.memberData;
      console.log("memberData：", memberData);
      this.priceDiffs = [];
      // 找到当前用户的会员等级
      const currentMembership = memberData.find(item => item.id === this.id);
      // 计算当前用户升级到其他会员等级的价格差
      for (let i = 0; i < memberData.length; i++) {
        if (memberData[i].id > currentMembership.id) {
          const diff = memberData[i].price - currentMembership.price;
          this.priceDiffs.push({
            from: currentMembership.title,
            to: memberData[i].title,
            difference: diff
          });
        }
      }
    },
    //升级到vip--彈窗
    upgradeVIP() {
      this.showModal = true;
      this.showInput = false;
      this.modalTitle = '升級至VIP會員';
      // 获取当前用户的会员等级
      const currentMembership = this.memberData.find(item => item.id === this.id);
      // 找到VIP会员的价格
      const vipMembership = this.memberData.find(item => item.id === 2); // 已知VIP会员的id是2
      // 计算升级到VIP会员的价格差
      this.priceDiff = vipMembership.price - currentMembership.price;
      if (this.priceDiff > 0) {
        this.modalDescription = `您正在申請升級為VIP會員,升級需補齊差價$${this.priceDiff}.00,升級後將會享有更多會員權益`;
        this.modalActionText = '立即升級';
        this.handleModalConfirm = this.handleUpgradeVIP;
      } else {
        // 处理当前会员等级已经是VIP的情况
        this.priceDiff = 0;
        this.modalDescription = '無需再次升級。';
        this.modalActionText = '知道了';
        this.handleModalConfirm = () => {
          this.showModal = false;
        };
      }
    },
    //升级到vip--操作
    handleUpgradeVIP() {
      this.submitData({
        type: 2,
        member_id: 2
      });
    },
    //升级到钻石--彈窗
    upgradeDiamond() {
      this.showModal = true;
      this.showInput = false;
      this.modalTitle = '升級至鑽石會員';
      // 获取当前用户的会员等级
      const currentMembership = this.memberData.find(item => item.id === this.id);
      // 找到钻石会员的价格
      const diamondMembership = this.memberData.find(item => item.id === 3); // 已知钻石会员的id是3
      // 计算升级到钻石会员的价格差
      this.priceDiff = diamondMembership.price - currentMembership.price;
      if (this.priceDiff > 0) {
        this.modalDescription = `您正在申請升級為鑽石會員,升級需補齊差價$${this.priceDiff}.00,升級後將會享有更多會員權益`;
        this.modalActionText = '立即升級';
        this.handleModalConfirm = this.handleUpgradeDiamond;
      } else {
        // 处理当前会员等级已经是钻石的情况
        this.modalDescription = '您当前已经是鑽石會員,無需再次升級。';
        this.modalActionText = '知道了';
        this.priceDiff = 0;
        this.handleModalConfirm = () => {
          this.showModal = false;
        };
      }
    },
    //升级到钻石--操作
    handleUpgradeDiamond() {
      this.submitData({
        type: 2,
        member_id: 3
      });
    },
    //兑换年费--彈窗
    exchange(){
      this.showModal = true;
      this.showInput = false;
      this.modalTitle = '積分兌換次年會費';
      // 找到当前用户的会员等级
      const currentMembership = this.memberData.find(item => item.id === this.id);
      // 获取当前会员等级的价格
      const currentPrice = currentMembership.price;
      this.priceDiff = 0
      // 判断当前积分是否足够兑换
      if (this.points >= currentPrice) {
        // 积分足够
        this.priceDiff = 0
        this.modalDescription = `您正在申請積分兌換次年會費，需會費$${currentPrice}.00, 無需額外費用，可直接兌換`;
        this.handleModalConfirm = () => {
          this.showModal = false;
        };
      } else {
        this.priceDiff = currentPrice - this.points;
        // 积分不足,显示差值
        this.modalDescription = `您正在申請積分兌換次年會費，需會費$${currentPrice},由於您的積分根據1積分=$1.00的規則，需額外補齊$${currentPrice - this.points}.00`;
      }
      this.modalActionText = '立即兌換';
      this.handleModalConfirm = this.handleExchange;
    },
    //兑换年费--操作
    handleExchange(){
      this.submitData({
        type: 3,
      });
    },
    //续费--彈窗
    renewal(){
      this.showModal = true;
      this.showInput = false;
      this.modalTitle = '會員續費';
      // 找到当前用户的会员等级
      const currentMembership = this.memberData.find(item => item.id === this.id);
      // 获取当前会员等级的价格
      this.priceDiff = Number(currentMembership.price);
      this.modalDescription = `您的會員到期日期為：${this.expirationDate}，您正在申請續費，續費成功後繼續享有普通會員權益`;
      this.modalActionText = '立即續費';
      this.handleModalConfirm = this.handleRenewal;
    },
    //续费--操作
    handleRenewal(){
      this.submitData({
        type: 1,
      });
    },
    //提现--彈窗
    withdraw() {
      this.showModal = true;
      this.showInput = true;
      this.modalTitle = '積分提現';
      this.modalDescription = `您正在申請積分提現，現有積分${this.points}點，根據積分規則1積分=$1.00，最多可提現${this.points}.00`;
      this.modalActionText = '立即提現';
      this.priceDiff =0
      if (this.points >= this.priceDiff) {
        this.$root.emitter.on('input-value-change', (inputValue) => {
          this.priceDiff = Number(inputValue);
          console.log("打印出来", this.priceDiff)
        });
      }else {
        this.priceDiff =0
      }
      this.handleModalConfirm = this.handleWithdraw;
    },
    //提现--操作
    handleWithdraw() {
      // 判断输入的数字是否小于 this.points
      if (this.priceDiff <= this.points) {
        this.submitData({
          type: 4,
          number: this.priceDiff
        });
        this.showModal = false;
        this.showInput = false;
      } else {
        this.showModal = false;
        this.isPopupVisible = true;
        this.popupText = "提現失敗！您输入的金额超过了积分總數!";
        setTimeout(() => {
          this.isPopupVisible = false;
        }, 2000)
      }
    },


    //提交数据--调用通过接口数据
    submitData(data){
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      approval(formData).then((res)=>{
        if (res.data.code===200){
          this.showModal = false;
           //判断不同跳转
          if (data.type === 4) {   //4是提现，不需要跳转成功页
            this.dialogVisible = true
            this.$emit('update:visible', true);
          } else if (data.type === 3) { // 兑换年费
            // 找到当前用户的会员等级
            const currentMembership = this.memberData.find(item => item.id === this.id);
            // 获取当前会员等级的价格
            const currentPrice = currentMembership.price;
            if (this.points >= currentPrice) {
              // 积分足够
              this.dialogVisible = true
              this.$emit('update:visible', true);
            } else {
              // 积分不足
              this.isPopupVisible = true
              this.popupText="申請已提交!"
              setTimeout(() => {
                this.isPopupVisible = false;
                this.$router.push('/success');
              }, 2000);
            }
          }else{
            this.isPopupVisible = true
            this.popupText="申請已提交!"
            setTimeout(() => {
              this.isPopupVisible = false;
              this.$router.push('/success');
            }, 2000);
          }
        }else if(res.data.code===401){
          this.isPopupVisible = true;
          this.popupText = res.data.message;
          setTimeout(() => {
            this.isPopupVisible = false;
          }, 2000)
        }
      })
    },
    //小弹窗点击确认后刷新页面
    handleDialogVisibleChange(visible) {
      this.dialogVisible = visible;
      if (!visible) {
        this.$router.go(0);
      }
    },
  },
  beforeUnmount() {
    this.$root.emitter.off('input-value-change');
  },
}
</script>

<style scoped>
.info-2{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-2-1{
  width: 554px;
  min-height: 264px;
  padding: 30px;
  margin: 10px;
  background: url("~@/assets/img/pic28.png") no-repeat;
  position: relative;
  border-radius: 4px;
}
.info21-1{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  color: #fff;
}
.info21-1 p{
  padding-top: 10px;
}
.info21-1 h1 {
  padding: 10px 0 0 0;
}
.info21-1 h1 span{
  font-size: 14px;
  padding-left: 5px;
}
.info21-1 h3{
  display: flex;
  justify-content: space-between;
}
.info21-1 h3 a{
  font-size: 15px;
  font-weight: normal;
  color: #FFF;
}
.info21-2{
  display: flex;
  align-items:center;
}
.info21-2>div{
  margin-right: 10px;
  padding: 10px 20px;
  border-radius: 4px;
  background: linear-gradient(5.58deg, #FFECD6 3.97%, #F1CFA6 95.14%);
  cursor: pointer;
}
.hui b{
  padding: 10px 0;
  display: block;
  font-size: 20px;
}
@media only screen and (max-width: 992px) {
  .info-2{
    flex-wrap: wrap;
  }
  .info-2-1{
    width: 100%;
    padding: 10px;
    margin: 0 0 20px 0;
  }
  .info21-2>div{
    margin: 10px 0;
  }

  .info21-2{
    flex-wrap: wrap;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: flex-start;
    align-items: flex-start;
  }

  .info21-1{
    flex-wrap: wrap;
  }
}

</style>