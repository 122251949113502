<template>
  <div class="header">
    <div class="logo">
      <router-link to="/">
        <img src="@/assets/img/logo.png">
      </router-link>
    </div>
    <div class="top-right">
      <div class="navBar">
        <button class="navbar-toggle" @click="isMenu = !isMenu">
          <span class="navbar-toggle-icon"></span>
          <span class="navbar-toggle-icon"></span>
          <span class="navbar-toggle-icon"></span>
        </button>
        <div class="navBarList" :class="{'menuOpen':!isMenu}">
          <!--     nav     -->
          <nav class="headerUl">
            <ul>
              <li>
                <router-link to="/about" :class="{'active': $route.name === 'About'}">關於我們</router-link>
              </li>
              <li>
                <router-link to="/news" :class="{'active': $route.name === 'News' || $route.name === 'NewsDetails'}">最新消息</router-link>
              </li>
              <li>
                <router-link to="/contact" :class="{'active': $route.name === 'Contact'}">聯絡我們</router-link>
              </li>
            </ul>
          </nav>
          <!--     members     -->
          <div class="members">
             <div class="logined" v-if="infoShow">
                <router-link to="/member">
                  會員中心
                </router-link>
             </div>
            <div class="unlogin" v-else>
              <router-link to="/login">
                登錄
              </router-link>
              <span>/</span>
              <router-link to="/register">
                註冊
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="block"></div>
</template>

<script>

import { ref } from 'vue';

export default {
  name: "Header",
  setup() {
    const sidebarOpen = ref(false);
    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };

    return {
      sidebarOpen,
      toggleSidebar,
    };
  },
  data() {
    return {
      language: localStorage.getItem("language") || 'en',
      isMenu: true,
      isLinkActive: false,
      showSubMenu: false,
      infoShow: localStorage.getItem('token') ? true : false
    };
  },
  // created() {
  //   const userData = localStorage.getItem('token');
  //     if(userData !== null){
  //         console.log("userData", userData)
  //     }else{
  //       localStorage.clear();
  //     }
  // },
  watch: {
    //点击隐藏下拉
    $route(to){
      if (document.body.clientWidth < 1023){
        console.log(document.body.clientWidth)
        this.isMenu = true
      }
      if (to.name === "Video_Category" || to.name === "District"){
        this.isLinkActive = true
      }else {
        this.isLinkActive = false
      }
    }
  }
};
</script>

<style scoped>

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 60px;
  /*不固定宽度需要的代码*/
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  /**/
  transition: background-color 0.3s ease;
  background-color: #fff;
}
.block{
  height: 80px;
}
.navbar-toggle-icon{
  width: 24px;
  height: 2px;
  background-color: #000;
  display: block;
  margin: 5px 0;
}
.logoImg img {
  height: 50px;
}
.headerUl>ul {
  display: flex;
  align-items: center;
}
.headerUl ul li>.active,
.headerUl>ul>li:hover>a {
  color: var(--color);
}
.headerUl ul li> a.active, .nav-have a.active{
 color: var(--color);
}
.headerUl>ul>li>a, .nav-have a{
  display: inline-block;
  line-height: inherit;
  font-size: 18px;
  font-weight: 600;
  padding: 0 20px;
  color: #000;
}

.headerUl>ul>li>a {
  padding: 0 20px;
}

.navbar-toggle {
  display: none;
}
.nav-have{
  position: relative;
}

.main-panel {
  min-width: 140px;
  position: absolute;
  top:20px;
  left: 0;
  padding: 10px;
  display: none;
  z-index: 9;
}
.main-panel ul{
  background-color: #fff;
  margin-top: 10px;
  padding: 10px;
}
.main-panel ul li{
  padding: 10px;
}
.nav-have:hover .main-panel {
  display: block;
}
.lang span.active, .m-lang span.active{
  color: var(--background);
  font-weight: bold;
}
.navBarList{
  display: flex;
  align-items: center;
}
.members{
  display: flex;
  align-items: center;
  padding-left: 100px;
}
.unlogin{
  border: 1px solid #000;
  border-radius: 4px;
  color: #000;
}
.logined{
  border: 1px solid var(--color);
  background: var(--color);
  border-radius: 4px;
}
.logined a, .unlogin a {
  padding: 8px 12px;
  display: inline-block;
  color: #fff;
}
.unlogin a{
  color: #000;
}

.logo img{
  height: 60px;
}
@media only screen and (max-width: 1399px) {
  .header{
    padding: 10px;
  }
}


@media only screen and (max-width: 1100px) {
  .headerUl>ul>li>a, .nav-have a {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 1099px) {
  .headerUl>ul>li>a, .nav-have a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 992px) {
  .header{
    height: 60px;
    padding: 10px;
  }
  .block{
    height: 60px;
  }
  .logo{
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .logo img{
    height: 40px;
  }
  .top-right{
    width: 100%;
  }
  .navBar{
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .navbar-toggle {
    display: block;
    position:relative;
    z-index: 1;
    background: transparent;
    border: none;
    padding: 0;
  }
  .navBarList {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background:#fff;
    z-index: 9;
  }

  .menuOpen {
    display: block;
    max-height: 300px;
  }

  .menuOpen{
    display: block;
    max-height: 500px;
  }

  .headerUl>ul{
    display: block;
    text-align: center;
  }
  .headerUl>ul>li{
    padding: 10px 0;
  }

  .members{
    justify-content: center;
     padding: 10px 0 20px 0;
  }

  .main-panel {
    min-width:100%;
    top:0;
    left: 0;
    display: block;
    position: relative;
  }
}

</style>