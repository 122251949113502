<template>
<div class="footer">
  <div class="container">
    <div class="sm-logo">
      <img src="~@/assets/img/logo-bottom.png">
    </div>
    <div class="bottom-nav">
      <ul>
        <li v-if="isLogin" >
          <router-link to="/member" >會員中心 </router-link>
        </li>
        <li>
          <router-link to="/about">關於我們</router-link>
        </li>
        <li>
          <router-link to="/news">最新消息</router-link>
        </li>
        <li>
          <router-link to="/contact">聯絡我們</router-link>
        </li>
      </ul>
    </div>
    <div class="copyright">
      <p>Copyright @{{ currentYear }} The Association Of HongKong Insurance FAM. All rights reserved</p>
    </div>
  </div>
</div>

</template>

<script>

export default {
  name: "Footer",
  data(){
    return{
      currentYear: new Date().getFullYear(),
      tel:'',
      whatsapp:'',
      isLogin: localStorage.getItem('token') ? true : false
    }
  },

}
</script>

<style scoped>
.footer{
  padding: 50px 0 30px 0;
  background: #000;
}
.sm-logo{
  display: flex;
}

.sm-logo img{
  height: 50px;
  margin: 0 auto;
}

.bottom-nav ul{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
}

.bottom-nav ul li a{
  padding: 0 60px;
  font-size: 18px;
  color: rgba(255, 255 ,255, .65);
}

.bottom-nav ul li a:hover{
  color: rgba(255, 255 ,255, 1);
}

.bottom-info{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}
.bottom-info div {
  padding: 0 20px;
}
.bottom-info div a{
  color: #fff;
}
.bottom-info div span{
  font-size:18px;
  font-weight: bold;
}

.copyright p{
  border-top: 1px solid #020E13;
  padding-top: 30px;
  color: #CECECE;
  text-align: center;
  font-size: 15px;
}


@media only screen and (min-width:0) and (max-width:767px){
  .sm-logo img{
    height: 30px;
  }
  .footer{
    padding: 20px 0;
  }
  .bottom-nav ul{
    flex-wrap: wrap;
     padding-top: 10px;
    justify-content: space-between;

  }
  .bottom-nav ul li{
    width: 25%;
    padding-top: 10px;
  }
  .bottom-nav ul li a{
    display: block;
   text-align: center;
    padding: 0 5px;
    font-size: 14px;
    text-align: center;
  }
  .bottom-info{
    flex-wrap: wrap;
  }
  .bottom-info div{
    width: 100%;
    padding: 3px 0;
    text-align: center;
  }
}
</style>